import React from 'react';
import { Paper, Typography } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/styles';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  ReferenceLine,
  Tooltip,
  Legend
} from 'recharts';

const useStyles = makeStyles(theme => ({
  paper: theme.paper
}));

const ProgressChart = ({ data }) => {
  const theme = useTheme();
  const { paper } = useStyles();

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload) {
      return (
        <Paper className={paper}>
          <Typography>{label}</Typography>
          <Typography>{`Game score : ${payload[0].payload.gameScore}`}</Typography>
          <Typography>{`Exam score : ${payload[0].payload.examScore}`}</Typography>
          <Typography>{`Summary score : ${payload[0].payload.summaryScore}`}</Typography>
          {payload[0].payload.totalTime && (
            <Typography>{`TotalTime : ${parseInt(
              payload[0].payload.totalTime
            )}`}</Typography>
          )}
        </Paper>
      );
    }

    return null;
  };

  return (
    <BarChart
      width={theme.breakpoints.width('md') - 150}
      height={300}
      data={data}
      margin={{
        top: 5,
        right: 30,
        left: 20,
        bottom: 5
      }}
    >
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="name" />
      <YAxis />
      <Tooltip content={<CustomTooltip />} />
      <Legend />
      <ReferenceLine y={0} stroke="#000" />
      <Bar dataKey="summaryScore" fill="#1ABC9C" />
    </BarChart>
  );
};

export default ProgressChart;
