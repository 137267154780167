import React, { useState } from 'react';
import axios from 'axios';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button
} from '@material-ui/core';
import Layout from '../components/Layout';
import Upload from '../components/Upload';
import { api } from 'common/firebase';

const Settings = () => {
  const [loading, setLoading] = useState(false);
  const [snackbar, setSnackBar] = useState(false);
  const [message, setMessage] = useState('');
  const [openDialog, setOpenDialog] = useState(false);
  const [targetFile, setTargetFile] = useState(null);

  const handleOpenConfirm = file => {
    setTargetFile(file);
    setOpenDialog(true);
  };
  const handleUpload = async file => {
    setOpenDialog(false);
    setLoading(true);
    const formData = new FormData();
    formData.append('file', file);
    try {
      await axios({
        method: 'post',
        url: `${api}/upload/users`,
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        data: formData
      });
      setMessage(`นำเข้าสำเร็จ`);
    } catch (e) {
      console.error(e);
      setMessage('เกิดข้อผิดพลาด');
    } finally {
      setLoading(false);
      setSnackBar(true);
    }
  };

  return (
    <Layout>
      <Upload
        topic="Import Users"
        loading={loading}
        snackbar={snackbar}
        setSnackBar={setSnackBar}
        message={message}
        handleUpload={handleOpenConfirm}
      />

      <Dialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{'Confirm upload?'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Do you confirm to import user?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)} color="primary">
            Cancel
          </Button>
          <Button
            onClick={() => handleUpload(targetFile)}
            color="primary"
            autoFocus
          >
            Upload
          </Button>
        </DialogActions>
      </Dialog>
    </Layout>
  );
};

export default Settings;
