import React, { useCallback, useEffect, useState } from 'react';
import { Button, CircularProgress, Paper, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import classNames from 'classnames';
import qs from 'querystringify';
import moment from 'moment';

import Layout from '../components/Layout';
import ProgressChart from '../components/ProgressChart';
import ProgressTable from '../components/ProgressTable';
import { fetchProgress } from '../services/Progress';

const useStyles = makeStyles(theme => ({
  paper: theme.paper,
  row: theme.row,
  header: {
    justifyContent: 'space-between'
  },
  progress: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '64px'
  }
}));

const limit = 20;

const ProgressDetail = ({ history, location }) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const { paper, row, header, progress } = useStyles();
  const fetch = useCallback(async uid => {
    try {
      const docs = await fetchProgress(uid, limit);
      setLoading(false);
      console.log('docs: ', docs);
      setData(
        docs.map(item => ({
          ...item,
          name: moment(item.createdAt).format('DD-MM-YYYY HH:mm')
        }))
      );
    } catch (error) {
      console.error(error);
    }
  }, []);
  useEffect(() => {
    const params = qs.parse(location.search);
    console.log('params: ', params);
    fetch(params.uid);
  }, [location.search, fetch]);

  return (
    <Layout>
      <Paper className={paper}>
        <Button onClick={() => history.goBack()}>Back</Button>
        <div className={classNames(row, header)}>
          {loading ? (
            <div className={progress}>
              <CircularProgress />
            </div>
          ) : null}
          <Typography variant="h4">Progress Detail</Typography>
        </div>
        <div>
          <ProgressChart data={data} />
        </div>
        <div>
          <ProgressTable data={data} />
        </div>
      </Paper>
    </Layout>
  );
};

export default ProgressDetail;
