import React, { useEffect, useReducer } from 'react';
import { auth } from 'common/firebase';

const AuthContext = React.createContext();
const initialState = {
  loading: true,
  user: null
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'AUTH_CHANGED':
      const { user } = action.payload;
      return {
        ...state,
        loading: false,
        isAdmin: user && user.email === 'admin@baac.com'
      };
    default:
      return state;
  }
};

export const AuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const value = { state, dispatch };

  useEffect(() => {
    auth.onAuthStateChanged(user => {
      dispatch({ type: 'AUTH_CHANGED', payload: { user } });
    });
  }, []);
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
export default AuthContext;
