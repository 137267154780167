import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@material-ui/core';

const ProgressTable = ({ data }) => {
  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell align="center">Date</TableCell>
            <TableCell align="center">Game score</TableCell>
            <TableCell align="center">Exam score</TableCell>
            <TableCell align="center">Summary score</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((item, index) => (
            <TableRow key={index}>
              <TableCell align="center">{item.name}</TableCell>
              <TableCell align="center">{item.gameScore}</TableCell>
              <TableCell align="center">{item.examScore}</TableCell>
              <TableCell align="center">{item.summaryScore}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ProgressTable;
