import React, { useContext } from 'react';
import { Switch, Route, Redirect, withRouter } from 'react-router-dom';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import AuthContext from './context/auth';
import Login from './screens/Login';
import Home from './screens/Home';
import Ranking from './screens/Ranking';
import Progress from './screens/Progress';
import ProgressDetail from './screens/ProgressDetail';
import Setting from './screens/Setting';

const App = ({ location }) => {
  const { state } = useContext(AuthContext);
  if (state.loading) return <div>Loading ...</div>;
  if (state.isAdmin && location.pathname === '/login')
    return <Redirect to="/" />;
  if (!state.isAdmin && location.pathname !== '/login')
    return <Redirect to="/login" />;

  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <Switch>
        <Route path="/" component={Home} exact />
        <Route path="/login" component={Login} exact />
        <Route path="/ranking" component={Ranking} exact />
        <Route path="/progress" component={Progress} exact />
        <Route path="/progress/detail" component={ProgressDetail} />
        <Route path="/setting" component={Setting} exact />
      </Switch>
    </MuiPickersUtilsProvider>
  );
};

export default withRouter(App);
