import React, { useState } from 'react';
import {
  FilledInput,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Typography
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import classNames from 'classnames';
import Layout from '../components/Layout';
import RankingTable from '../components/RankingTable';

import rankingService from '../services/Ranking';

const useStyles = makeStyles(theme => ({
  paper: theme.paper,
  row: theme.row,
  header: {
    justifyContent: 'space-between'
  },
  formControl: {
    marginLeft: '8px'
  },
  select: {
    minWidth: '200px'
  },
  datePicker: {
    display: 'inline-flex',
    marginLeft: '8px',
    formControl: {
      color: 'yellow',
      filledInput: {
        dateFormatInput: {
          height: '100%'
        }
      }
    }
  },
  datePickerInput: {
    height: 'auto'
  }
}));

const Ranking = () => {
  const [scoreType, setScoreType] = useState('summaryScore');
  const [period, setPeriod] = useState('alltime');
  const { paper, row, header, formControl, select } = useStyles();

  const onChangePeriod = event => {
    setPeriod(event.target.value);
  };

  const handleExport = async e => {
    const { docs } = await rankingService.getRank(scoreType, period);
    rankingService.exportData(scoreType, period, docs);
  };
  return (
    <Layout>
      <Paper className={paper}>
        <div className={classNames(row, header)}>
          <Typography variant="h4">Ranking</Typography>
          <div>
            <FormControl className={formControl} variant="filled">
              <InputLabel htmlFor="period-select">Period</InputLabel>
              <Select
                className={select}
                value={period}
                onChange={onChangePeriod}
                input={<FilledInput id="period-select" />}
              >
                <MenuItem value={'alltime'}>All Time</MenuItem>
                <MenuItem value={'month'}>This Month</MenuItem>
                <MenuItem value={'lastmonth'}>Last Month</MenuItem>
              </Select>
            </FormControl>
            <FormControl className={formControl} variant="filled">
              <InputLabel htmlFor="game-select">ประเภท</InputLabel>
              <Select
                className={select}
                value={scoreType}
                onChange={event => setScoreType(event.target.value)}
                input={<FilledInput id="game-select" />}
              >
                <MenuItem value={'gameScore'}>คะแนนส่วนเกม</MenuItem>
                <MenuItem value={'examScore'}>คะแนนส่วนแบบทดสอบ</MenuItem>
                <MenuItem value={'summaryScore'}>คะแนนรวม</MenuItem>
              </Select>
            </FormControl>
          </div>
        </div>
        <div>
          {['gameScore', 'examScore', 'summaryScore'].map(
            current =>
              scoreType === current && (
                <RankingTable
                  key={current}
                  typeScore={current}
                  time={period}
                  onExport={handleExport}
                />
              )
          )}
        </div>
      </Paper>
    </Layout>
  );
};

export default Ranking;
