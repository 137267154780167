import axios from 'axios';
import { auth, api, database } from 'common/firebase';
import XLSX from 'xlsx';

const fetchProfiles = async (limit, pageType, uid, search, searchType) => {
  console.log(`search: ${search} type: ${searchType}`);
  try {
    const token = await auth.currentUser.getIdToken();
    const res = await axios({
      method: 'get',
      url: `${api}/profile/all`,
      headers: { Authorization: `Bearer ${token}` },
      params: {
        limit: limit ? limit : null,
        pageType: pageType,
        uid,
        search,
        searchType,
      },
    });
    console.log('data: ', res.data);
    return { ...res.data };
  } catch (e) {
    console.log('get score error: ', e);
    throw e;
  }
};

const fetchProgress = async (uid, limit) => {
  const res = await database
    .collection('progress')
    .doc(uid)
    .collection('scores')
    .orderBy('createdAt', 'asc')
    .limit(limit)
    .get();
  let docs = [];
  res.docs.forEach(
    (doc) =>
      (docs = [
        ...docs,
        { ...doc.data(), createdAt: doc.data().createdAt.toDate() },
      ])
  );
  return docs;
};
const exportData = async (start, end) => {
  //date format DD-MM-YYYY
  try {
    const token = await auth.currentUser.getIdToken();
    const res = await axios({
      method: 'get',
      url: `${api}/progress/summary/range`,
      headers: { Authorization: `Bearer ${token}` },
      params: {
        start: start.format('DD-MM-YYYY'),
        end: end.format('DD-MM-YYYY'),
      },
    });
    let docs = res.data.data;
    docs = docs.map((doc) => ({ ...doc, summary: doc.summary }));
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(docs);
    XLSX.utils.book_append_sheet(wb, ws, 'report');
    XLSX.writeFile(
      wb,
      `report-progress-${start.format('DDMMYYYY')}-${end.format(
        'DDMMYYYY'
      )}.xlsx`
    );
    return { ...res.data };
  } catch (e) {
    console.log('get score error: ', e);
    throw e;
  }
};
export { fetchProfiles, fetchProgress, exportData };
