import firebase from 'firebase';

const config = {
  apiKey: 'AIzaSyDNU7raYSunYvM7Du_7j-MUvBawtysHGzU',
  authDomain: 'progaming-baac.firebaseapp.com',
  databaseURL: 'https://progaming-baac.firebaseio.com',
  projectId: 'progaming-baac',
  storageBucket: 'progaming-baac.appspot.com',
  messagingSenderId: '1040439457585'
};
firebase.initializeApp(config);
const api = 'https://asia-northeast1-progaming-baac.cloudfunctions.net';
const auth = firebase.auth();
const database = firebase.firestore();
const storage = firebase.storage();
const fieldValue = firebase.firestore.FieldValue;
export { api, auth, database, fieldValue, storage };
