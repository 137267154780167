import React, { useState } from 'react';
import {
  Button,
  CircularProgress,
  Paper,
  Snackbar,
  Typography
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  paper: theme.paper,
  fileLabel: {
    marginLeft: theme.spacing(2)
  },
  progress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12
  },
  row: theme.row,
  uploadButton: {
    marginRight: theme.spacing(1)
  }
}));

const Upload = ({
  handleUpload,
  loading,
  message,
  snackbar,
  setSnackBar,
  topic
}) => {
  const [file, setFile] = useState(null);
  const { fileLabel, paper, progress, row, uploadButton } = useStyles();

  return (
    <Paper className={paper}>
      <div className={row}>
        <Typography variant="h4">{topic}</Typography>
      </div>
      <div className={row}>
        <Button variant="contained" component="label">
          Browse
          <input
            type="file"
            style={{ display: 'none' }}
            onChange={e => setFile(e.target.files[0])}
          />
        </Button>
        <Typography className={fileLabel} inline="true">
          {file && file.name}
        </Typography>
      </div>
      <div className={row}>
        <Button
          className={uploadButton}
          variant="contained"
          color="primary"
          onClick={() => handleUpload(file)}
          disabled={file === null || loading}
        >
          Upload
          {loading && <CircularProgress size={24} className={progress} />}
        </Button>
      </div>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        open={snackbar}
        autoHideDuration={3000}
        onClose={() => setSnackBar(false)}
        message={<span>{message}</span>}
      />
    </Paper>
  );
};

export default Upload;
