import React, { useEffect, useState, useContext, useCallback } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
  CircularProgress,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { NavigateBefore, NavigateNext } from '@material-ui/icons';
import AuthContext from '../context/auth';
import { fetchProfiles } from '../services/Progress';

const useStyles = makeStyles({
  controlPanel: {
    display: 'flex',
    padding: '16px 8px',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  controlPanelItem: {
    marginRight: '4px',
  },
  progress: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '64px',
  },
});
const limit = 10;
const ProgressList = ({ search }) => {
  const [items, setItems] = useState([]);
  const [page, setPage] = useState(1);
  const [itemPerPage, setItemPerPage] = useState(limit);
  const [hasOtherPage, setHasOtherPage] = useState(false);
  const [forSearch, setForSearch] = useState({ text: '', type: 'uid' });
  const [loading, setLoading] = useState(true);
  const { state } = useContext(AuthContext);
  const { controlPanel, controlPanelItem, progress } = useStyles();
  const fetch = useCallback(
    async (pageType, uid) => {
      const { docs, hasOtherPage } = await fetchProfiles(
        limit,
        pageType,
        uid,
        forSearch.text === '' ? null : forSearch.text,
        forSearch.text === '' ? null : forSearch.type
      );
      setItems(docs);
      setHasOtherPage(hasOtherPage);
      setLoading(false);
    },
    [forSearch.text, forSearch.type]
  );

  useEffect(() => {
    if (!state.loading) {
      setItemPerPage(limit);
      fetch();
    }
  }, [fetch, state.loading]);
  useEffect(() => {
    setForSearch(search);
  }, [search]);
  const prevPage = async () => {
    setPage(page - 1);
    setLoading(true);
    setItems([]);
    const { docs } = await fetchProfiles(limit, 'prev', items[0].uid);
    setItems(docs);
    setHasOtherPage(true);
    setLoading(false);
  };
  const nextPage = async () => {
    setPage(page + 1);
    setLoading(true);
    setItems([]);
    const { docs, hasOtherPage } = await fetchProfiles(
      limit,
      'next',
      items[items.length - 1].uid
    );
    setItems(docs);
    setHasOtherPage(hasOtherPage);
    setLoading(false);
  };
  return (
    <>
      <List>
        {loading ? (
          <div className={progress}>
            <CircularProgress />
          </div>
        ) : null}
        {items.map((item, index) => {
          return (
            <ListItem
              key={index}
              button
              component={RouterLink}
              to={`/progress/detail?uid=${item.uid}`}
            >
              <ListItemText
                primary={`${(page - 1) * itemPerPage + (index + 1)}. ${
                  item.uid
                } - ${item.name}`}
              />
            </ListItem>
          );
        })}
      </List>
      <Divider />
      <div className={controlPanel}>
        {items.length === 0 ? null : (
          <Typography className={controlPanelItem}>
            {1 + (page - 1) * itemPerPage} -{' '}
            {itemPerPage * (page - 1) + items.length} of many
          </Typography>
        )}
        <IconButton
          className={controlPanelItem}
          disabled={page === 1 || loading}
          onClick={prevPage}
        >
          <NavigateBefore />
        </IconButton>
        <IconButton
          className={controlPanelItem}
          disabled={items.length < itemPerPage || loading || !hasOtherPage}
          onClick={nextPage}
        >
          <NavigateNext />
        </IconButton>
      </div>
    </>
  );
};

export default ProgressList;
