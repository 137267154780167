import React, { useState } from 'react';
import {
  Button,
  CircularProgress,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import classNames from 'classnames';
import { DatePicker } from '@material-ui/pickers';
import moment from 'moment';
import Layout from '../components/Layout';
import ProgressList from '../components/ProgressList';

import { exportData } from '../services/Progress';

const useStyles = makeStyles((theme) => ({
  paper: theme.paper,
  row: theme.row,
  header: {
    justifyContent: 'space-between',
  },
  formControl: {
    marginLeft: '8px',
  },
  select: {
    minWidth: '200px',
  },
  datePicker: {
    display: 'inline-flex',
    marginLeft: '8px',
    formControl: {
      color: 'yellow',
      filledInput: {
        dateFormatInput: {
          height: '100%',
        },
      },
    },
  },
  datePickerInput: {
    height: 'auto',
  },
  exportItem: {
    padding: '0px 5px',
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
}));
const limitDays = 45;
const Progress = () => {
  const [loading, setLoading] = useState(false);
  const [showSearch, setShowSearch] = useState({ text: '', type: 'uid' });
  const [search, setSearch] = useState({ text: '', type: 'id' });
  const [exportFilter, setExportFilter] = useState({
    start: moment(),
    end: moment(),
    endMinDate: moment().add(limitDays, 'd'),
  });
  const {
    paper,
    row,
    header,
    formControl,
    exportItem,
    buttonProgress,
    wrapper,
  } = useStyles();
  const handleChange = (key) => (e) => {
    setShowSearch({ ...showSearch, [key]: e.target.value });
  };
  const handleSearch = (e) => {
    e.preventDefault();
    setSearch(showSearch);
  };
  const handleExportDateChange = (key) => (date) => {
    const prepareFilter = { ...exportFilter };
    if (key === 'start') {
      if (prepareFilter.end.isBefore(date)) {
        prepareFilter.end = date;
      }
      prepareFilter.endMinDate = moment(date).add(limitDays, 'd');
    }
    prepareFilter[key] = date;
    setExportFilter({ ...prepareFilter });
  };
  const handleExport = async (e) => {
    setLoading(true);
    const { start, end } = exportFilter;
    await exportData(start, end);
    setLoading(false);
  };
  return (
    <Layout>
      <Paper className={paper}>
        <div className={classNames(row, header)}>
          <Typography variant="h4">Progress</Typography>
        </div>
        <form onSubmit={handleSearch}>
          <Grid container justify="flex-start" alignItems="flex-start">
            <Grid item xs={6}>
              <TextField
                label="Search"
                value={showSearch.text}
                onChange={handleChange('text')}
                fullWidth
              />
            </Grid>
            <Grid item xs={4} style={{ paddingRight: '20px' }}>
              <FormControl className={formControl} fullWidth>
                <InputLabel>ประเภทข้อมูล</InputLabel>
                <Select value={showSearch.type} onChange={handleChange('type')}>
                  <MenuItem value="uid">รหัสผู้เล่น</MenuItem>
                  <MenuItem value="name">ชื่อ</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={1}>
              <Button variant="contained" onClick={handleSearch} fullWidth>
                Search
              </Button>
            </Grid>
          </Grid>
        </form>
        <div>
          <ProgressList search={search} />
        </div>
        <Grid
          container
          justify="flex-end"
          alignItems="center"
          style={{ padding: '16px 8px' }}
        >
          <Grid item className={exportItem}>
            <InputLabel>Start</InputLabel>
            <DatePicker
              disableFuture={true}
              value={exportFilter.start}
              onChange={handleExportDateChange('start')}
              disabled={loading}
            />
          </Grid>
          <Grid item className={exportItem}>
            <InputLabel>End</InputLabel>
            <DatePicker
              disableFuture={true}
              minDate={exportFilter.start}
              maxDate={exportFilter.endMinDate}
              value={exportFilter.end}
              onChange={handleExportDateChange('end')}
              disabled={loading}
            />
          </Grid>
          <Grid item>
            <div className={wrapper}>
              <Button
                variant="contained"
                onClick={handleExport}
                color="primary"
                disabled={loading}
              >
                {' '}
                Export
              </Button>
              {loading && (
                <CircularProgress size={24} className={buttonProgress} />
              )}
            </div>
          </Grid>
        </Grid>
      </Paper>
    </Layout>
  );
};

export default Progress;
